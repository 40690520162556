/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Buttons
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-player [data-media-button]) {
  -webkit-tap-highlight-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: var(--media-button-border-radius, 2px);
  color: var(--media-button-color, var(--media-controls-color, #f5f5f5));
  width: var(--media-button-size, 40px);
  height: var(--media-button-size, 40px);
  transition: transform 0.2s ease-out;
}

:where(media-player[data-fullscreen] [data-media-button]) {
  width: var(--media-fullscreen-button-size, 42px);
  height: var(--media-fullscreen-button-size, 42px);
}

:where(media-player[data-bp-x='sm'][data-fullscreen] [data-media-button]) {
  width: var(--media-mobile-fullscreen-button-size, 42px);
  height: var(--media-mobile-fullscreen-button-size, 42px);
}

:where(media-player)
  :where(
    [data-media-button] > svg,
    [data-media-button] > media-icon,
    [data-media-button] > shadow-root > svg,
    [data-media-button] > shadow-root > media-icon
  ) {
  width: var(--media-button-icon-size, 80%);
  height: var(--media-button-icon-size, 80%);
  border-radius: var(--media-button-border-radius, 2px);
}

:where(media-player [data-media-button] > shadow-root) {
  display: contents;
}

:where(media-player [data-media-button]:not([default-appearance]) > shadow-root:not(:only-child)) {
  display: none;
}

:where(media-player [data-media-button] [slot]) {
  display: none !important;
}

:where(media-player)
  :where(
    media-toggle-button[data-pressed] [slot='on'],
    media-toggle-button:not([data-pressed]) [slot='off'],
    media-play-button[data-paused]:not([data-ended]) [slot='play'],
    media-play-button[data-ended] [slot='replay'],
    media-play-button:not([data-paused]) [slot='pause'],
    media-mute-button[data-volume='high'] [slot='volume-high'],
    media-mute-button[data-volume='low'] [slot='volume-low'],
    media-mute-button[data-volume='muted'] [slot='volume-muted'],
    media-mute-button[data-volume='muted'] [slot='unmute'],
    media-mute-button:not([data-volume='muted']) [slot='mute'],
    media-pip-button:not([data-pip]) [slot='enter'],
    media-pip-button[data-pip] [slot='exit'],
    media-caption-button:not([data-pressed]) [slot='off'],
    media-caption-button[data-pressed] [slot='on'],
    media-fullscreen-button:not([data-fullscreen]) [slot='enter'],
    media-fullscreen-button[data-fullscreen] [slot='exit'],
    media-seek-button:not([seconds*='-']) [slot='forward'],
    media-seek-button[seconds*='-'] [slot='backward'],
    media-menu [data-media-menu-button][data-pressed] [slot='close'],
    media-menu [data-media-menu-button]:not([data-pressed]) [slot='open']
  ) {
  display: inline-block !important;
}

:where(media-player [data-media-button][aria-hidden='true']) {
  display: none;
}

@media (pointer: fine) {
  :where(media-player [data-media-button][data-hover]) {
    background-color: var(--media-button-hover-bg, rgb(255 255 255 / 0.2));
  }

  :where(media-player [data-media-button][data-hover]) {
    transform: var(--media-button-hover-transform, scale(1));
    transition: var(--media-button-hover-transition, transform 0.2s ease-in);
  }
}

@media (pointer: coarse) {
  :where(media-player [data-media-button][data-hover]) {
    border-radius: var(--media-button-touch-hover-border-radius, 100%);
    background-color: var(--media-button-touch-hover-bg, rgb(255 255 255 / 0.2));
  }
}
